import { createSelector } from 'reselect';
import { dataFormatterUtils, sort } from 'utils';

const getPlayersData = (state: any) => state.playersReportReducer.players;
const getPlayersTotalLineData = (state: any) =>
    state.playersReportReducer.total;
const getPlayersSortedData = (state: any) =>
    state.playersReportReducer.sortedPlayersData;
export const getPlayers = createSelector(
    getPlayersData,
    (state) =>
        state &&
        sort(
            {
                keyToSort: 'login',
                sortState: 'asc',
            },
            dataFormatterUtils.getFormattedData(state),
        ),
);
export const getPlayersTotalLine = createSelector(
    getPlayersTotalLineData,
    (state) => state,
);
export const getSortedPlayers = createSelector(
    getPlayersSortedData,
    (state) => state,
);
