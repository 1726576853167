import { IAction } from 'helpers/interfaces';
import { dataFormatterUtils, dateFormatter, localStorageHelper, sort } from 'utils';
import { IPlayerData } from 'redux/reducers/backoffice/player-report-reducer';
import { PlayersReportTypes } from 'redux/actions/backoffice/players-report-actions';

export interface IPlayersData {
    playerId: number;
    externalId: string;
    login: string;
    spins: number;
    bet: number;
    win: number;
    net: number;
    rtp: number;
    link: string;
    freeRoundsBet: number | null;
    freeRoundsNet: number | null;
    freeRoundsRtp: number | null;
    freeRoundsSpins: number | null;
    freeRoundsWin: number | null;
    testPlayer: boolean;
    currencyId: number;
    fromDate: string;
    toDate: string;
}

export interface IPlayers {
    players?: IPlayersData[];
    sortedPlayersData: IPlayerData[];
}

export const playersInitialState: IPlayers = {
    sortedPlayersData: [],
};

const playersReportReducer = (state = playersInitialState, actions: IAction) => {
    switch (actions.type) {
        case PlayersReportTypes.PLAYERS_REPORT_REQUEST: {
            const { fromDate, toDate } = actions.payload;

            localStorageHelper.setChainedValue('user.reports.dates', {
                fromDate,
                toDate,
            });

            return playersInitialState;
        }

        case PlayersReportTypes.PLAYERS_REPORT_SUCCESS: {
            const { content, total } = actions.payload;
            const dates = localStorageHelper.getChainedValue('user.reports.dates');
            const fromDate = (dates && dates.fromDate) || dateFormatter.getDefaultDate();
            const toDate = (dates && dates.toDate) || dateFormatter.getDefaultDate(false);

            content.forEach((player: IPlayersData) => {
                player.fromDate = dateFormatter.getFormattedForReportDate(fromDate);
                player.toDate = dateFormatter.getFormattedForReportDate(toDate);
                player.link = `/casino/players/${player.playerId}/${player.login}`;
            });

            return {
                players: content,
                total,
                sortedPlayersData: [],
            };
        }

        case PlayersReportTypes.PLAYERS_REPORT_SORT: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedPlayersData: sortState
                    ? sort(actions.payload, dataFormatterUtils.getFormattedData(state.players))
                    : [],
            };
        }

        default:
            return state;
    }
};

export default playersReportReducer;
