import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../commonStyles.scss';
import { IPlayersData } from 'redux/reducers/backoffice/players-report-reducer';
import { getPlayersReport } from 'redux/actions/backoffice/players-report-actions';
import {
    getPlayers,
    getPlayersTotalLine,
} from 'redux/selectors/backoffice/players-report-selectors';
import { getServerError } from 'redux/selectors/backoffice/error-selectors';
import { useTranslation } from 'react-i18next';
import { currencyUtils, dateFormatter, localStorageHelper } from '../../../utils';
import { useCallback, useEffect, useState } from 'react';
import { ContentComponent } from '../../../Components/ContentComponent/ContentComponent';
import { Link } from 'react-router-dom';
import { Context } from '../../../App';
import TableHeaderWrapper from '../../../Components/Table/TableHeaderWrapper';
import { HeaderTitleComponent } from '../../../Components/ContentComponent/HeaderTitleComponent';
import TotalLineComponent from '../../../Components/TotalLineComponent/TotalLineComponent';
import { gamesReportData } from '../columnsData';
import { ErrorComponent } from '../../Errors/ErrorComponent';
import { Button, Checkbox, Divider, Empty, Modal, Radio, Table } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Loader } from '../../../Components/Loader/Loader';
import { PlayersColumns } from './PlayersColumns';
import { getDateOfLastRefresh } from '../../../redux/selectors/user-selectors';

const PlayersReportComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data: IPlayersData[] = useSelector(getPlayers);
    const totalLine: any = useSelector(getPlayersTotalLine);
    const error: string = useSelector(getServerError);
    const dateOfLastRefresh: Date = useSelector(getDateOfLastRefresh);
    const activeCurrencyCode = currencyUtils.getActiveCurrencyCode();
    const columns = PlayersColumns(activeCurrencyCode);
    const options = columns.map(({ key }) => ({
        label: t(key),
        value: key,
    }));
    const initialDates = localStorageHelper.getChainedValue('user.reports.dates');

    const [tableSize, setTableSize] = useState<'small' | 'middle' | 'large'>('middle');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [checkedList, setCheckedList] = useState(columns.map((item) => item.key));
    const [fromDate, setFromDate] = useState<Date>(
        initialDates?.fromDate || dateFormatter.getDefaultDate(),
    );
    const [toDate, setToDate] = useState<Date>(
        initialDates?.toDate || dateFormatter.getDefaultDate(false),
    );
    const visibleColumns = columns.filter((column) => checkedList.includes(column.key));
    const totalWidth = visibleColumns.reduce((total, col) => total + (col.width || 0), 0);

    const calculateTableHeight = useCallback(() => {
        const height = window.innerHeight * 0.8 - 194;
        setTableHeight(height);
    }, []);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const getReportData = useCallback(() => {
        dispatch(getPlayersReport({ fromDate, toDate }));
    }, [fromDate, toDate, dispatch]);

    const handlePageSizeChange = (current: any, size: any) => {
        setPageSize(size);
    };

    const parentReportCallback = (dates: { fromDate: Date; toDate: Date }) => {
        setFromDate(dates.fromDate);
        setToDate(dates.toDate);
    };

    const reportData = data ? (data.length ? data : []) : null;

    useEffect(() => {
        getReportData();
    }, [getReportData, fromDate, toDate]);

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, [calculateTableHeight]);

    return (
        <ContentComponent
            customBreadcrumbs={<Link to={`/casino/reports`}>{t('reports')}</Link>}
            header={
                <Context.Consumer>
                    {(context) => (
                        <TableHeaderWrapper
                            titleComponent={
                                <HeaderTitleComponent title={t('players_performance')} />
                            }
                            parentReportCallback={parentReportCallback}
                            getReport={getReportData}
                            fromDate={fromDate}
                            toDate={toDate}
                            reportKey="players"
                            context={context}
                        />
                    )}
                </Context.Consumer>
            }
            preHeader={
                reportData && reportData.length && !error ? (
                    <TotalLineComponent data={totalLine} columns={gamesReportData} />
                ) : undefined
            }
            innerContent={
                error ? (
                    <ErrorComponent error={error} />
                ) : reportData ? (
                    reportData.length ? (
                        <div className="casino_performance-table-container">
                            <div className={'casino_performance-table-title'}>
                                <div className="content-wrapper">
                                    <>
                                        <Button
                                            style={{ marginRight: '8px' }}
                                            onClick={toggleModal}
                                        >
                                            <SettingOutlined />
                                        </Button>
                                        Data updated at:{' '}
                                        {dateFormatter.getFormattedDateOfLastRefresh(
                                            dateOfLastRefresh,
                                        )}
                                    </>
                                </div>
                                <Modal
                                    title="Table tools"
                                    open={isModalVisible}
                                    onOk={toggleModal}
                                    onCancel={toggleModal}
                                >
                                    <Divider>Columns displayed</Divider>
                                    <Checkbox.Group
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            marginBottom: '20px',
                                        }}
                                        options={options}
                                        value={checkedList}
                                        onChange={(checkedValues) => {
                                            setCheckedList(checkedValues.map(String));
                                        }}
                                    />
                                    <Divider>Columns displayed</Divider>
                                    <div style={{ marginBottom: 16 }}>
                                        <span>Change Table Cell Size: </span>
                                        <Radio.Group
                                            value={tableSize}
                                            onChange={(e) => setTableSize(e.target.value)}
                                        >
                                            <Radio.Button value="large">Large</Radio.Button>
                                            <Radio.Button value="middle">Middle</Radio.Button>
                                            <Radio.Button value="small">Small</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </Modal>
                            </div>
                            <Table
                                style={{
                                    minWidth: '100%',
                                    paddingBottom: '16px',
                                }}
                                className={'casino_performance-table'}
                                dataSource={reportData}
                                scroll={{ x: totalWidth, y: tableHeight }}
                                columns={visibleColumns}
                                pagination={{
                                    pageSize,
                                    position: ['topRight'],
                                    pageSizeOptions: [10, 20, 50, 100],
                                    onShowSizeChange: handlePageSizeChange,
                                }}
                                size={tableSize}
                                rowKey={(record) =>
                                    `${record.fromDate.toString()}-${record.toDate.toString()}-${record.playerId.toString()}-${record.currencyId.toString()}`
                                }
                                footer={() => <></>}
                            />
                        </div>
                    ) : (
                        <Empty description={t('no_data')} />
                    )
                ) : (
                    <Loader style={{ height: '90vh' }} />
                )
            }
        />
    );
};

export default PlayersReportComponent;
