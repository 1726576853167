import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
    playersReportSuccess,
    PlayersReportTypes,
} from '../../actions/backoffice/players-report-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
    currencyUtils,
    dateFormatter,
    getLoginRequestHeader,
    localStorageHelper,
} from '../../../utils';
import { of } from 'rxjs';
import { IAction, IStateObservable } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const playersReportEpic = (action$: Observable<IAction>, state$: IStateObservable) =>
    action$.pipe(
        ofType(PlayersReportTypes.PLAYERS_REPORT_REQUEST),
        mergeMap((action) => {
            const requestBody = {
                includeTestPlayers:
                    localStorageHelper.getChainedValue('user.preferences.displayTestData') ||
                    state$.value.userReducer.shouldDisplayTestData,
                playerCurrencyIds: currencyUtils.getSelectedCurrenciesIdsData(),
                start: dateFormatter.getFormattedStartDate(action.payload.fromDate),
                end: dateFormatter.getFormattedEndDate(action.payload.toDate),
                currencyBreakdown: localStorageHelper.getChainedValue(
                    'user.reports.filters.currenciesBreakdown',
                )
                    ? 'currency'
                    : 'none',
            };

            const headers = getLoginRequestHeader();

            return from(
                axios.post(`${SERVER_URL}/reports/revenue/by-player`, requestBody, {
                    headers,
                }),
            ).pipe(
                map((response) => playersReportSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PlayersReportTypes.PLAYERS_REPORT_REQUEST)),
                ),
            );
        }),
    );
